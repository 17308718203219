import React from "react";
import Casviewbystatus from "./casviewbystatus";

export default function InvalidCases() {
  return (
    <>
      <Casviewbystatus status="Invalid" heading="Invalid Cases" />
    </>
  );
}
