import React from "react";
import Casviewbystatus from "./casviewbystatus";

export default function ErrorCases() {
  return (
    <>
      <Casviewbystatus status="Error" heading="Error Cases" />
    </>
  );
}
