import React, { useEffect, useState } from "react";
import { getAllAccess } from "../../access/accesservice";
import {
  documentsupload,
  getAllStaff,
  getstaffById,
  updateUserBulkStatus,
} from "../staffservice";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { getCasestatussummery } from "../../dashboard/dashboardservice";
import { formatDate } from "../../../utils/dateformat";
import { showToast } from "../../../utils/notification";
import { Button, Spinner } from "react-bootstrap";
import { RiExternalLinkFill } from "react-icons/ri";
import { getAllClients } from "../../client/clientservice";
import Select from "react-select";
import { extractFileExtension } from "../../../utils/common";
import { useAuth } from "../../../authprovider";

export default function Staffdetail() {
  const { userDetail } = useAuth();

  const Navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const val = searchParams.get("id");
  const userId = searchParams.get("userId");
  const [UserDetail, setUserDetail] = useState(null);
  const [roleList, setRoleList] = useState([]);
  const [statussummery, setStatusSummery] = useState(null);
  const [files, setFiles] = useState([]);
  const [loder, setLoder] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);

  const fetchClientList = async () => {
    const result = await getAllClients(1, 100000);
    if (result) {
      const clientlist = result.docs;
      const modefyoptions = clientlist.map((item) => ({
        label: item.clientId,
        value: item._id,
      }));

      setClientList(modefyoptions);
    }
  };

  const handleFileChange = (e) => {
    // setFiles(e.target.files);
    setFiles(Array.from(e.target.files));
  };

  const handleUpload = async () => {
    if (files.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
      setLoder(true);
      const response = await documentsupload(userId, formData);
      setLoder(false);
      if (response && response.status == 200) {
        showToast("all attachements upload successfully", "success");
        setFiles([]);
        fetchUserById();
      } else if (response && response.status == 401) {
        showToast(response.msg, "error");
      }
    }
  };

  const deleteFile = (indexToDelete) => {
    const updatedFiles = files.filter((file, index) => index !== indexToDelete);
    setFiles(updatedFiles);
  };

  const fetchallAccessList = async () => {
    const response = await getAllAccess(1, 10000);
    if (response) {
      setRoleList(response.docs);
    }
  };

  const fetchUserById = async () => {
    if (userId) {
      const response = await getstaffById(userId);
      if (response) {
        setUserDetail(response);
      }
    }
  };

  const fetchcasestatus = async () => {
    const response = await getCasestatussummery({ userId: userId.trim() });

    if (response) {
      setStatusSummery(response);
    }
  };

  const fetchStaffList = async () => {
    const response = await getAllStaff(1, 10000);
    if (response) {
      setEmployeeList(response.docs);
    }
  };

  const updateStatus = async (status) => {
    let data = [
      {
        id: UserDetail._id,
        status: status,
        check: true,
      },
    ];
    const response = await updateUserBulkStatus({ status: data });
    if (response && response.status == 200) {
      if (UserDetail.roleId.roleName == "Admin") {
        showToast(" Sorry we can't update admin status", "warning");
      } else {
        showToast(" status updated successfully", "success");
      }

      fetchUserById();
    }
  };

  useEffect(() => {
    fetchallAccessList();
    fetchUserById();
    fetchcasestatus();
    fetchClientList();
    fetchStaffList();
  }, []);
  return (
    <>
      <div className="content-wrapper">
        <div className="d-flex mb-4 align-content-center">
          <div className="page-header">
            <h1 className="page-title">
              <span> #{val}</span> - Employee Detail
            </h1>
          </div>
          <div className="ms-auto d-flex">
            <div className="ms-2">
              <select
                onChange={(e) => {
                  const val = e.target.value;
                  if (val) {
                    updateStatus(val);
                  }
                }}
                className="form-select tr_btns_select"
              >
                <option value={""}> Select Status</option>
                <option value={true}> Active </option>
                <option value={false}> Inactive </option>
              </select>
            </div>

            <button
              onClick={() => {
                Navigate(`/employee-update?userId=${userId}&id=${val}`);
              }}
              type="button"
              className="btn btn-light btn-icon-text tr_btns ms-2"
            >
              <i className=" icon-note btn-icon-prepend"></i> Edit{" "}
            </button>

            <button
              type="button"
              className="btn btn-light btn-icon-text ms-2 tr_btns"
              data-bs-toggle="collapse"
              data-bs-target="#employee_detail_collapse"
              aria-expanded="false"
              aria-controls="employee_detail_collapse"
            >
              <i className=" icon-options-vertical btn-icon-prepend"></i> More
              Info{" "}
            </button>
          </div>
        </div>

        <div className="collapse" id="employee_detail_collapse">
          <div className="cdc_body">
            <div className="form-group d-flex mb-4">
              <div className="flex-fill">
                <label>Created By</label>
                <p className="h6">{UserDetail?.createdBy?.name}</p>
              </div>

              <div className="flex-fill ms-3">
                <label>Created On</label>
                <p className=" h6">{formatDate(UserDetail?.createdAt)}</p>
              </div>

              <div className="flex-fill ms-3">
                <label>New</label>
                <p className="cnew_color h5 font-weight-bold">
                  {statussummery?.New ? statussummery.New?.count : 0}
                </p>
              </div>

              <div className="flex-fill ms-3">
                <label>In Process</label>
                <p className="text-warning h5 font-weight-bold">
                  {statussummery?.In_Process
                    ? statussummery.In_Process?.count
                    : 0}
                </p>
              </div>

              <div className="flex-fill ms-3">
                <label>Closed</label>
                <p className="cclosed_color h5 font-weight-bold">
                  {" "}
                  {statussummery?.Closed ? statussummery.Closed?.count : 0}
                </p>
              </div>

              <div className="flex-fill ms-3 CNV_ov ">
                <label>CNV</label>
                <p className="  h5 font-weight-bold">
                  {" "}
                  {statussummery?.CNV ? statussummery.CNV?.count : 0}
                </p>
              </div>

              <div className="flex-fill ms-3 error_ov">
                <label> Error </label>
                <p className=" h5 font-weight-bold">
                  {" "}
                  {statussummery?.Error ? statussummery.Error?.count : 0}
                </p>
              </div>

              <div className="flex-fill ms-3 invalid_ov">
                <label> Invalid </label>
                <p className="h5 font-weight-bold">
                  {" "}
                  {statussummery?.Invalid ? statussummery.Invalid?.count : 0}
                </p>
              </div>

              <div className="flex-fill ms-3">
                <label> Total </label>
                <p className="text-primary h5 font-weight-bold">
                  {" "}
                  {statussummery?.totalCount ? statussummery.totalCount : 0}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 grid-margin">
            <div>
              <form className="forms-sample mb-4">
                <div className="form-group row mb-4">
                  <div className="col-md-6  mb-4">
                    <label>Status</label>
                    <p className="text-success">
                      {UserDetail?.status ? "Active" : "In active"}
                    </p>
                  </div>

                  <div className="col-md-6  mb-4">
                    <label>Name</label>
                    <p>{UserDetail?.name}</p>
                  </div>

                  <div className="col-md-6  mb-4">
                    <label>Designation</label>
                    <p>{UserDetail?.designation}</p>
                  </div>
                  <div className="col-md-6  mb-4">
                    <label> Mobile Number </label>
                    <p>{UserDetail?.mobileNumber}</p>
                  </div>
                  <div className="col-md-6  mb-4">
                    <label>Email ID</label>
                    <p>{UserDetail?.emailId}</p>
                  </div>

                  <div className="col-md-6  mb-4">
                    <label>Address </label>
                    <p>{UserDetail?.address}</p>
                  </div>

                  <div className="col-md-6  mb-4">
                    <label>Username </label>
                    <p>{UserDetail?.userName}</p>
                  </div>
                  {UserDetail?.manageBy && (
                    <div className="col-md-6  mb-4">
                      <label>Manage By </label>
                      <p>{UserDetail?.manageBy.name}</p>
                    </div>
                  )}
                </div>

                {/* <button className="btn btn-primary">Update</button>
                <button className="btn btn-light ms-2">Cancel</button> */}
              </form>
            </div>
          </div>

          <div className="col-md-6 grid-margin  form-group">
            <div className="card  mb-4">
              <div className="card-body">
                <div className="col-md-12  mb-4 ">
                  <label>Role </label>
                  <select
                    disabled={true}
                    required={true}
                    id="roleId"
                    name="roleId"
                    // onChange={formik.handleChange}
                    value={UserDetail?.roleId?._id}
                    className="form-select"
                  >
                    <option value={""}> Select </option>
                    {roleList.map((item, i) => (
                      <option key={i} value={item?._id}>
                        {item.roleName}
                      </option>
                    ))}
                  </select>
                </div>
                {UserDetail?.roleId?.roleName == "Client" && (
                  <>
                    <div className="col-md-12  mb-4 ">
                      <label>Clients </label>
                      <Select
                        isDisabled={true}
                        value={UserDetail?.clients ? UserDetail.clients : []}
                        name="clients"
                        isMulti
                        options={clientList}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(val) => {
                          // formik.setFieldValue("clients", val);
                        }}
                      />
                    </div>
                    <div className="col-md-12  mb-4">
                      <label>Manage By </label>
                      <select
                        required={true}
                        disabled={true}
                        // onChange={formik.handleChange}
                        value={UserDetail?.manageBy?._id}
                        className="form-select"
                      >
                        <option selected> Not assign </option>
                        {employeeList.map((item, i) => (
                          <option key={i} value={item._id}>
                            {item?._id == userDetail?._id ? "Self" : item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                )}
                {UserDetail?.roleId &&
                  UserDetail.roleId.roleName == "Employee" && (
                    <div className="row">
                      <div className="d-md-flex">
                        <label>Add Case Permission</label>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-check">
                          <label className="form-check-label">
                            <input
                              disabled={true}
                              type="radio"
                              className="form-check-input"
                              name="createCase"
                              checked={UserDetail.createCase}
                              onChange={() => {}}
                            />
                            Yes
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-check">
                          <label className="form-check-label">
                            <input
                              disabled={true}
                              type="radio"
                              className="form-check-input"
                              name="createCase"
                              checked={!UserDetail.createCase}
                              onChange={() => {
                                // userDetail.setFieldValue("createCase", false);
                              }}
                            />
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
            {/* <p>{JSON.stringify(UserDetail)}</p> */}
            <div className="card  mb-4">
              <div className="card-body">
                <div className="d-md-flex form-group">
                  <label>Files</label>
                </div>
                <div className="row">
                  <div className="col-md-12 ">
                    <div>
                      {loder ? (
                        <Spinner />
                      ) : (
                        <>
                          {files.map((item, i) => (
                            <div key={i} className="attached_filed">
                              <div>{item.name}</div>{" "}
                              <span
                                onClick={() => {
                                  deleteFile(i);
                                }}
                              >
                                <i className="icon-close"> </i>
                              </span>
                            </div>
                          ))}
                        </>
                      )}
                      <div className="my-3">
                        <input
                          type="file"
                          multiple={true}
                          class="form-control custom_upload"
                          onChange={handleFileChange}
                        />
                      </div>
                      {UserDetail?.documents && (
                        <div style={{ maxHeight: "20svh", overflowY: "auto" }}>
                          {UserDetail?.documents.map((item, i) => (
                            <Link
                              to={item}
                              target="_blank"
                              key={i}
                              className="attached_filed"
                            >
                              <div>{`${val} .${extractFileExtension(
                                item
                              )}`}</div>
                              <span>
                                <RiExternalLinkFill />
                              </span>
                            </Link>
                          ))}
                        </div>
                      )}

                      {files.length > 0 && (
                        <Button
                          disabled={loder}
                          variant="primary"
                          className="mt-3"
                          onClick={handleUpload}
                        >
                          Upload
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
