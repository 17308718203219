import React from "react";
import { Navigate } from "react-router-dom";
import { showToast } from "../utils/notification";
import { useAuth } from "../authprovider";

export default function RolebaseControle({
  element: Element,

  hasAccess,
  redirectTo = "/",
}) {
  if (!hasAccess && Element) {
    showToast("Sorry, you don't have permission to access", "warning");
    return <Navigate to={redirectTo} replace />;
  }

  return <Element />;
}
