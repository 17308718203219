import Routerdata from "./routex";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "./authprovider";
import Datepicker2 from "./sharecomponent/datepicker/datepicker2";

function App() {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        // transition: Slide,
      />
      <AuthProvider>
        <Routerdata />
      </AuthProvider>
    </>

    //  <><Sidebar /><Dashboard/><Footer/></>
  );
}

export default App;
