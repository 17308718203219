import { getRequest, postRequest, putRequest } from "../../utils/axiosrequests";

export const getAllClients = (pageno, pagesize) => {
  return postRequest("/client", {
    pageno: pageno,
    pagesize: pagesize,
  })
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const getClientById = (id) => {
  return getRequest(`/client/${id}`)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const updateClientDetails = (clientId, data) => {
  return putRequest(`/client/${clientId}`, data)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const createNewClient = (data) => {
  return postRequest(`/client/create`, data)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const getAllClientsByStatus = (status = true) => {
  return postRequest("/client/data/getclientbystatus", {
    status: status,
  })
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const updateClientBulkStatus = (data) => {
  return putRequest(`/client/data/bulkstatusupdate`, data)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const getAllClientfreesearch = (data) => {
  return postRequest("/client/data/search", data)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};
