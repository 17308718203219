import React from "react";
import Casviewbystatus from "./casviewbystatus";
import { useAuth } from "../../authprovider";

export default function Caseassingtome() {
  const { userDetail } = useAuth();
  return (
    <>
      <Casviewbystatus assignTo={userDetail.userid} heading="Assigned To Me" />
    </>
  );
}
