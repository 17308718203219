import React, { useEffect, useState } from "react";
import Caseform from "../caseform";
import { getCaseById, updateCaseDetails } from "../caseservice";
import { useParams, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../../utils/notification";

export default function Caseupdate() {
  const [searchParams] = useSearchParams();
  const val = searchParams.get("id");
  const caseId = searchParams.get("caseid");
  const navigate = useNavigate();
  // const caseid = "1804339f-a45d-425f-a05f-8c3ffe87aa5e";
  const [caseDetails, setCaseDetails] = useState(null);
  const [formData, setFormData] = useState(null);
  const [loder, setLoder] = useState(false);
  const createnewpayload = (value) => {
    const payload = {
      clientId: value?.clientId ? value.clientId : "",
      productType: value?.productType ? value.productType : "",
      productName: value.productName ? value.productName : "",
      vendorId: value.vendorId ? value.vendorId : "",
      accountNumber: value.accountNumber,
      requirement: value.requirement,
      productType: value.productType,
      source: value.source,
      assignTo: value.assignTo ? value.userDetails.userid : "",
      remark: value.remark,
      status: value.status,
    };
    setCaseDetails(payload);
  };

  const updatecase = async (value) => {
    let payload = {};

    const createpayload = {
      clientId: value.clientId,
      productType: value.productType,
      productName: value.productName,
      vendorId: value.vendorId,
      accountNumber: value.accountNumber,
      requirement: value.requirement,
      productType: value.productType,
      source: value.source,
      assignTo: value.assignTo,
      remark: value.remark,
      requirement: value.requirement,
      status: value.status,
    };
    payload = createpayload;

    setLoder(true);
    const response = await updateCaseDetails(caseId, payload);
    setLoder(false);
    if (response && response.status == 200) {
      showToast("Case Updated Successfully", "success");
      navigate("/all-cases");
    } else if (response && response.status == 400) {
      showToast("Account Number Already Exist", "warning");
    }
  };

  const fetchCaseDetail = async () => {
    const response = await getCaseById(caseId);
    if (response) {
      createnewpayload(response);
    }
  };

  useEffect(() => {
    fetchCaseDetail();
  }, []);

  return (
    <>
      <Caseform
        casedata={caseDetails}
        submitvalue={(val) => {
          if (val) {
            updatecase(val);
          }
        }}
        loder={loder}
        redurecturl={`/case-detail?caseId=${caseId}&id=${val}`}
      />
    </>
  );
}
