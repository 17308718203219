import React from "react";
import Spinner from "react-bootstrap/Spinner";

export default function SSpinner() {
  return (
 
    <div className="d-flex justify-content-center align-items-center custom_spincss">
      {/* <Spinner animation="border" /> */}
      <span class="v_loader"></span>
    </div>

  
  );
}
