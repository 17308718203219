import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { bulkachmentmultiplecases, bulkCaseattachment } from "../caseservice";
import { showToast } from "../../../utils/notification";
import SSpinner from "../../../sharecomponent/spinner/spinner";
import { useAuth } from "../../../authprovider";
import { ProgressBar } from "primereact/progressbar";

export default function Bulkuploadmodal(props) {
  const { socket } = useAuth();
  const [files, setFiles] = useState([]);
  const [loder, setLoder] = useState(false);
  const [progress, setProgress] = useState(0);
  const handleFileChange = (e) => {
    // setFiles(e.target.files);
    setFiles(Array.from(e.target.files));
  };

  const handleUpload = async () => {
    let casesid = props.caseId
      .map((item) => (item.check ? item.id : null))
      .filter((id) => id !== null);
    if (files.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
      if (casesid.length > 0) {
        formData.append("cases", JSON.stringify({ caseId: casesid }));
      }
      let response;
      if (casesid.length == 0) {
        setLoder(true);
        response = await bulkCaseattachment(formData);
        setLoder(false);
      } else {
        setLoder(true);
        response = await bulkachmentmultiplecases(formData);
        setLoder(false);
      }

      if (response && response.status == 200) {
        showToast("All Attachements Upload Successfully", "success");
        setProgress(0);
        setFiles([]);
        props.onhide(false);
      } else if (response && response.status == 201) {
        showToast(response.msg, "success");
      }
    }
  };

  const formatFileSize = (size) => {
    const units = ["bytes", "KB", "MB", "GB", "TB"];
    let index = 0;
    while (size >= 1024 && index < units.length - 1) {
      size /= 1024;
      index++;
    }
    return `${size.toFixed(2)} ${units[index]}`;
  };

  const deleteFile = (indexToDelete) => {
    const updatedFiles = files.filter((file, index) => index !== indexToDelete);
    setFiles(updatedFiles);
  };

  useEffect(() => {
    socket.on("uploadCaseId", (message) => {
      // showToast(
      //   `Attachments for caseID ${message.msg} successfully`,
      //   "success"
      // );
    });

    socket.on("uploadProgress", (message) => {
      setProgress(message.progress);
    });

    return () => {
      socket.off("uploadCaseId");
      socket.off("uploadProgress");
    };
  }, []);

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => {
          setFiles([]);
          props.onhide(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{"Uplaod"}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {progress > 0 && (
            <div className="mb-3">
              <ProgressBar value={progress}></ProgressBar>
            </div>
          )}
          <div class="">
            <div class="mb-2 d-flex align-items-center">
              <input
                type="file"
                multiple={true}
                class="form-control custom_upload"
                onChange={handleFileChange}
              />
              <small class="text-muted ms-2">
                {files.length} files selected below
              </small>
            </div>

            <table class="table attachment_table">
              <tbody>
                {files.map((item, i) => (
                  <tr key={i}>
                    <td>
                      <a href="#" class="case_id_ap" target="_blank">
                        {item.name}
                      </a>
                    </td>
                    {/* <td>
                        <a href="#" class="case_file_ap" target="_blank">
                          CID18.pdf
                        </a>
                      </td> */}
                    <td>
                      <div class="file_weight">{formatFileSize(item.size)}</div>
                    </td>
                    <td>
                      {" "}
                      <span
                        onClick={() => {
                          deleteFile(i);
                        }}
                        class="remove_case_file"
                      >
                        {" "}
                        <i class="icon-close"></i>{" "}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              props.onhide(false);
              setFiles([]);
            }}
            variant="secondary"
          >
            Close
          </Button>
          <Button
            disabled={loder}
            variant="primary"
            type="submit"
            onClick={() => {
              handleUpload();
            }}
          >
            upload
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
