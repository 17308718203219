import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import { AuthProvider, useAuth } from "./authprovider";
import ProtectedRoute from "./protectedroute";
import LoginPage from "./components/login";

import CnvCases from "./components/casebystatus/cnvcase";
import ErrorCases from "./components/casebystatus/caseerror";
import InProcessCases from "./components/casebystatus/caseinprogress";
import ClosedCases from "./components/casebystatus/caseclosed";
import InvalidCases from "./components/casebystatus/caseInvalid";

import ViewAllCases from "./components/case/caseview";

import ManageClients from "./components/client/clientview";

import Addcase from "./components/case/add/caseadd";
import UpdateCaseDetails from "./components/case/update/caseupdate";
import Vendorview from "./components/vendor/vendorview";

import CaseDetail from "./components/case/caseDetail/detail";
import AddedByMe from "./pages/AddedByMe";
import PublicRoute from "./publicroute";

import RolebaseControle from "./protectedroutes/rolebase_access_controll";
import Addnewstaff from "./components/staff/add/addnewstaff";
import Staffview from "./components/staff/staffview";
import Staffdetail from "./components/staff/staffdetail/staffdetail";
import Userupdate from "./components/staff/update/userupdate";
import Caseassingtome from "./components/casebystatus/caseassingtome";
import Profile from "./components/staff/profile/profile";
import Dashboard from "./components/dashboard/dashboard";
import Myperformance from "./components/staff/profile/myperformance";
import Resetpassword from "./components/staff/profile/resetpassword";
import Notification from "./components/dashboard/notification";
import NewCases from "./components/casebystatus/casesnew";

const Routerdata = () => {
  const { userDetail } = useAuth();

  return (
    <>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path="/login" element={<LoginPage />} />
        </Route>

        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Dashboard />} />
          <Route
            path="/add-new-case"
            element={<RolebaseControle element={Addcase} hasAccess={true} />}
          />
          <Route
            path="/add-new-employee"
            element={
              <RolebaseControle
                element={Addnewstaff}
                hasAccess={
                  userDetail?.roleId?.roleName == "Admin" ? true : false
                }
              />
            }
          />
          <Route
            path="/update-case"
            element={
              <RolebaseControle element={UpdateCaseDetails} hasAccess={true} />
            }
          />
          <Route path="/added-by-me" element={<AddedByMe />} />
          <Route
            path="/all-cases"
            element={
              <RolebaseControle element={ViewAllCases} hasAccess={true} />
            }
          />
          <Route path="/notifications" element={<Notification />} />
          <Route path="/case-detail" element={<CaseDetail />} />
          <Route path="/assigned-to-me" element={<Caseassingtome />} />
          <Route path="/change-password" element={<Resetpassword />} />
          <Route path="/closed-cases" element={<ClosedCases />} />
          <Route path="/cnv-cases" element={<CnvCases />} />
          <Route
            path="/employee-list"
            element={
              <RolebaseControle
                element={Staffview}
                hasAccess={
                  userDetail?.roleId?.roleName == "Admin" ? true : false
                }
              />
            }
          />
          <Route
            path="/employee-detail"
            element={
              <RolebaseControle
                element={Staffdetail}
                hasAccess={
                  userDetail?.roleId?.roleName == "Admin" ? true : false
                }
              />
            }
          />
          <Route
            path="/employee-update"
            element={
              <RolebaseControle
                element={Userupdate}
                hasAccess={
                  userDetail?.roleId?.roleName == "Admin" ? true : false
                }
              />
            }
          />
          <Route path="/error-cases" element={<ErrorCases />} />
          <Route path="/inprocess-cases" element={<InProcessCases />} />
          <Route path="/invalid-cases" element={<InvalidCases />} />
          <Route path="/new-cases" element={<NewCases />} />

          <Route
            path="/manage-clients"
            element={
              <RolebaseControle element={ManageClients} hasAccess={true} />
            }
          />
          <Route
            path="/manage-vendors"
            element={<RolebaseControle element={Vendorview} hasAccess={true} />}
          />
          <Route path="/my-performance" element={<Myperformance />} />
          <Route path="/profile" element={<Profile />} />
        </Route>
      </Routes>
    </>
  );
};

export default Routerdata;
