import { getRequest, postRequest, putRequest } from "../../utils/axiosrequests";

export const getCasestatussummery = (data) => {
  return postRequest(`/cases/data/casesummery`, data)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const gettopemploye = (data) => {
  return postRequest(`/cases/data/performance`, data)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const getstatussummeryByCaseId = (data) => {
  return postRequest(`/cases/data/casesummerybyclientid`, data)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const getallnotifications = (all = false) => {
  return postRequest(`/notification`, { all: all })
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const updatenotifications = (id, all = false) => {
  return putRequest(`/notification/${id}`, { read: true, all: all })
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const Yearsummery = (data) => {
  return postRequest(`/dashboard/annual-summary`, data)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};
