import React, { useEffect, useState } from "react";
import RangePicker from "react-range-picker";
import "./style.css";
import { FaCalendarDays } from "react-icons/fa6";
export default function Picker(props) {
  const [date, setDate] = useState([null, null]);
  useEffect(() => {
    props.ondatechange({
      from: date[0] ? new Date(date[0]) : null,
      to: date[1] ? new Date(date[1]) : null,
    });
  }, [date]);

  useEffect(() => {
    if (props.value == "") {
      setDate([null, null]);
    }
  }, [props.value]);

  const placeholder = ({ startDate, endDate }) => {
    if (!date[0] && !date[1]) {
      return (
        <div className="plceholdercal">
          <input type="text" disabled={false} className="form-control" />
          <FaCalendarDays className="iconcal" />
        </div>
      );
    }
    return (
      <>
        <div className="plceholdercal">
          <input
            type="text"
            value={`${date[0]} - ${date[1]}`}
            disabled={false}
            className="form-control"
          />
          <FaCalendarDays className="iconcal" />
        </div>
      </>
    );
  };
  return (
    <div>
      {/* {JSON.stringify(date)} */}
      <RangePicker
        name="requirement"
        selectsRange
        value
        // value={{
        //   startDate: date[0],
        //   endDate: date[1],
        // }}
        // defaultValue={{
        //   startDate: date[0],
        //   endDate: date[1],
        // }}
        // placeholder={placeholder}
        // placeholderText={`${date[0] ? date[0] : ""}-${date[1] ? date[1] : ""}`}
        date={false}
        rangeTillEndOfDay={true}
        onDateSelected={(start, end) => {
          // formik.setFieldValue("requirement", [start, end]);
          setDate([start, end]);
        }}

        // isClearable={true}
        // selectTime={true}
      />
    </div>
  );
}

// import { useState } from "react";
// import DateRangePicker from "@wojtekmaj/react-daterange-picker";
// import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
// import 'react-calendar/dist/Calendar.css'
// // type ValuePiece = Date | null;

// // type Value = ValuePiece | [ValuePiece, ValuePiece];
// export default function Picker() {
//   const [value, onChange] = useState([new Date(), new Date()]);

//   return (
//     <div>
//       <DateRangePicker onChange={onChange} value={value} />
//     </div>
//   );
// }
