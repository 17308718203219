import React from "react";
import Casviewbystatus from "./casviewbystatus";

export default function CnvCases() {
  return (
    <>
      <Casviewbystatus status="CNV" heading="CNV Cases" />
    </>
  );
}
