import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  getAllStaff,
  getAllusersfreesearch,
  updateUserBulkStatus,
} from "./staffservice";
import { formatDate } from "../../utils/dateformat";
import Pagenation from "../../sharecomponent/pagenation/page";
import { showToast } from "../../utils/notification";
import SSpinner from "../../sharecomponent/spinner/spinner";
function colorstyle(val) {
  if (val) {
    return {
      backgroundColor: "#E2F6EA",
      color: "#3FCC79",
    };
  } else {
    return {
      backgroundColor: "#F34D4D",
      color: "#FFC7C7",
    };
  }
}
export default function Staffview() {
  const navigator = useNavigate();
  const [usersList, setUserList] = useState([]);
  const [pageno, setPageno] = useState(1);
  const [limit, setLimit] = useState(0);
  const [pagesize, setpageSize] = useState(20);
  const [selectedstatus, setSelectedstatus] = useState("");
  const [updatemultiplestatus, setUpdatemultiplestatus] = useState([]);
  const [checkall, setCheckall] = useState(false);
  const [inputvalue, setinputvalue] = useState("");
  const [listloder, setListloder] = useState(false);
  const selectedItem = useMemo(() => {
    return updatemultiplestatus
      .map((item) => (item.check ? item.id : null))
      .filter((id) => id !== null);
  }, [updatemultiplestatus]);
  const fetchAllUsers = async () => {
    setListloder(true);
    const response = await getAllStaff(pageno, pagesize);
    setListloder(false);
    if (response) {
      const datalist = response.docs;
      setLimit(response.totalDocs);
      const modefystatus = datalist.map((item) => ({
        id: item._id,
        status: selectedstatus,
        check: false,
      }));
      setSelectedstatus("");
      setUpdatemultiplestatus(modefystatus);
      setUserList(datalist);
    }
  };

  const updatebulkstatus = async (modefyarr) => {
    const response = await updateUserBulkStatus({ status: modefyarr });
    if (response && response.status == 200) {
      showToast("All status updated successfully", "success");
      setCheckall(false);
      fetchAllUsers();
    } else {
      showToast("Sorry pleace select correct data", "warning");
    }
  };

  const fetchfreesearch = async (val) => {
    setListloder(true);
    const response = await getAllusersfreesearch({
      pageno: pageno,
      pagesize: pagesize,
      val: val,
    });
    setListloder(false);
    if (response) {
      setUserList(response.docs);
    }
  };

  useEffect(() => {
    if (selectedstatus) {
      const modefyarr = updatemultiplestatus.map((item) => ({
        ...item,
        status: selectedstatus,
      }));

      const filterarr = modefyarr.filter(
        (item) => item.check == true && item.status != null
      );

      if (filterarr.length > 0) {
        updatebulkstatus(filterarr);
      } else {
        showToast("Sorry pleace select correct data", "warning");
      }
    }
  }, [selectedstatus]);

  useEffect(() => {
    if (inputvalue) {
      fetchfreesearch(inputvalue);
    } else {
      fetchAllUsers();
    }
  }, [pageno, pagesize, inputvalue]);
  return (
    <>
      {" "}
      <div className="content-wrapper">
        <div className="page-header ">
          <h1 className="page-title">Employee List</h1>
        </div>

        <div className="d-flex mb-4 align-content-center">
          <div>
            <input
              value={inputvalue}
              onChange={(e) => {
                setinputvalue(e.target.value);
              }}
              type="text"
              className="form-control search_case_input"
              placeholder="Search Employee"
            />
          </div>
          <div className="ms-auto d-flex">
            <div className="ms-2">
              <select
                value={selectedstatus}
                onChange={(e) => {
                  setSelectedstatus(e.target.value);
                }}
                className="form-select tr_btns_select"
              >
                <option value={""}>Select</option>
                <option value={true}> Active</option>
                <option value={false}> Inactive</option>
              </select>
            </div>

            <button
              type="button"
              className="btn btn-light btn-icon-text ms-2 tr_btns"
              // data-bs-toggle="modal"
              // data-bs-target="#addNewClientBackdrop"
              onClick={() => {
                // handleShow();
                navigator("/add-new-employee");
              }}
            >
              <i className="icon-plus btn-icon-prepend"></i> Add New{" "}
            </button>
          </div>
        </div>

        <div className="collapse" id="advanced_filter_collapse">
          <div className="af_body">
            <div className="form-group d-flex mb-4">
              <div className="flex-fill">
                <label>By Staff Type</label>
                <select className="form-select">
                  <option>Select</option>
                  <option>3D Employee</option>
                  <option>Client as Staff</option>
                </select>
              </div>

              <div className="flex-fill ms-3">
                <label>By Status</label>
                <select className="form-select">
                  <option>Select</option>
                  <option>Active</option>
                  <option>Inactive</option>
                </select>
              </div>

              <div className="flex-fill ms-3">
                <label> &nbsp; </label>
                <button className="btn btn-primary d-block">Find</button>
              </div>
            </div>

            <ul className="d-flex filter_tags_list">
              <li>
                <div>
                  <span>Client as Staff</span>
                  <a href="#" className="remove_tag">
                    Remove
                  </a>
                </div>
              </li>

              <li>
                <div>
                  <span>Inactive </span>
                  <a href="#" className="remove_tag">
                    Remove
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 grid-margin ">
            {listloder && <SSpinner />}
            {selectedItem.length > 0 && (
              <div className="nofslect">
                <b>{selectedItem.length}</b> employee selected
              </div>
            )}
            <div className="table_parent">
              <table className="sm_font_size table table-striped">
                <thead>
                  <tr>
                    <th>
                      <input
                        checked={checkall}
                        onChange={() => {
                          const check = !checkall;
                          const modefyarr = updatemultiplestatus.map(
                            (item) => ({
                              id: item.id,
                              status: selectedstatus,
                              check: check,
                            })
                          );
                          setCheckall(check);
                          setUpdatemultiplestatus(modefyarr);
                        }}
                        type="checkbox"
                        className="form-check-input"
                      />
                    </th>
                    <th>ID.</th>
                    <th>Full Name</th>
                    <th>Username </th>
                    <th>Designation</th>
                    <th>Mobile</th>
                    <th>Email ID </th>
                    <th>Created On</th>
                    <th>Role</th>
                    <th>Created By</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {usersList.map((item, i) => (
                    <tr key={i}>
                      <td>
                        <input
                          disabled={
                            item.roleId.roleName == "Admin" ? true : false
                          }
                          checked={updatemultiplestatus[i]?.check}
                          onChange={() => {
                            const newarr = [...updatemultiplestatus];
                            let modefyval = newarr[i];

                            modefyval = {
                              ...modefyval,
                              check: !modefyval.check,
                            };

                            newarr[i] = modefyval;
                            setUpdatemultiplestatus(newarr);
                          }}
                          type="checkbox"
                          className="form-check-input"
                        />
                      </td>
                      <td>
                        <Link
                          to={`/employee-detail?userId=${item._id}&id=${item?.userid}`}
                        >
                          {/* 3D00 */}
                          {item?.userid}
                        </Link>
                      </td>
                      <td>{item?.name}</td>
                      <td>{item?.userName}</td>
                      <td>{item?.designation}</td>
                      <td>{item?.mobileNumber}</td>
                      <td>{item?.emailId}</td>
                      <td>{formatDate(item?.createdAt)}</td>
                      <td>{item?.roleId?.roleName}</td>
                      <td>{item?.createdBy?.name}</td>

                      <td>
                        <label
                          style={colorstyle(item.status)}
                          className="badge badge-success"
                        >
                          {item.status ? "Active" : "In-active"}{" "}
                        </label>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div class="d-flex align-items-center justify-content-center w-100 my-4">
                <Pagenation
                  page={pageno}
                  limit={pagesize}
                  total={limit}
                  onChange={(val) => {
                    setPageno(val);
                  }}
                  onChangeLimit={(val) => {
                    setpageSize(val);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
