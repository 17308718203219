import { getRequest, postRequest, putRequest } from "../../utils/axiosrequests";

export const getAllCases = (pageno, pagesize, caseorder = false) => {
  return postRequest("/cases", {
    pageno: pageno,
    pagesize: pagesize,
    caseorder: caseorder,
  })
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const getCaseById = (caseId) => {
  return getRequest(`/cases/${caseId}`)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const updatecasesvendor = (data) => {
  return putRequest(`/cases/data/updatecasesvendor`, data)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const updateCaseDetails = (caseId, data) => {
  return putRequest(`/cases/${caseId}`, data)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const updateCaseActivity = (caseId, data) => {
  return putRequest(`/cases/activity/${caseId}`, data)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const updateCaseStatus = (caseId, data) => {
  return putRequest(`/cases/status/${caseId}`, data)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const createNewCase = (data) => {
  return postRequest(`/cases/create`, data)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const getAllCasesByFilter = (data) => {
  return postRequest("/cases/findbyfilter", data)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const getdownloaddocs = (data) => {
  return postRequest(`/cases/data/downloaddocs`, data)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};
// /findbyfilter

export const getAllCasesfreesearch = (data) => {
  return postRequest("/cases/search", data)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const updateCaseBulkStatus = (data) => {
  return putRequest(`/cases/data/bulkstatusupdate`, data)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const getAllCasesByMultipleFilter = (data) => {
  return postRequest("/cases/findbyfilter", data)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const getalluploaddate = (data) => {
  return postRequest("/cases/upload", data)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const bulkCaseInsert = (data) => {
  return postRequest("/cases/data/bulkcaseinsert", data)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};
// /cases/uploaddocs

export const bulkCaseattachment = (data) => {
  return postRequest("/cases/uploaddocs", data)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const bulkachmentmultiplecases = (data) => {
  return postRequest("/cases/uploaddocsmultiplecases", data)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

// "/uploaddocscase/:caseId";

export const bulkCaseattachmentByCaseId = (data, id) => {
  return postRequest(`/cases/uploaddocscase/${id}`, data)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const TBill = ["Jio", "Vi", "Airtel"];

export const AccountSearch = ["By PAN", "BY Mobile"];

export const CIBIL = ["Commercial CIBIL", "Normal CIBIL"];

export const ITO = [
  "ITR",
  "FINANCIAL",
  "GST",
  "PAN",
  "PAN TO BANK",
  "PAN TO MOBILE",
  "PAN WITH MOBILE",
];

export const FieldVerification = [
  "RESIDENCE",
  "Business",
  "SALARY SLIP",
  "RENT AGREEMENT",
  "PROPERTY VERIFICATION",
  "PATWARI VERIFICATION",
  "OFFICE VERIFICATION",
];

export const banks = [
  "Allahabad Bank",
  "Andhra Bank",
  "Axis Bank",
  "Bank of Bahrain and Kuwait",
  "Bank of Baroda - Corporate Banking",
  "Bank of Baroda - Retail Banking",
  "Bank of India",
  "Bank of Maharashtra",
  "Canara Bank",
  "Central Bank of India",
  "City Union Bank",
  "Corporation Bank",
  "Deutsche Bank",
  "Development Credit Bank",
  "Dhanlaxmi Bank",
  "Federal Bank",
  "ICICI Bank",
  "IDBI Bank",
  "Indian Bank",
  "Indian Overseas Bank",
  "IndusInd Bank",
  "ING Vysya Bank",
  "Jammu and Kashmir Bank",
  "Karnataka Bank Ltd",
  "Karur Vysya Bank",
  "Kotak Bank",
  "Laxmi Vilas Bank",
  "Oriental Bank of Commerce",
  "Punjab National Bank - Corporate Banking",
  "Punjab National Bank - Retail Banking",
  "Punjab & Sind Bank",
  "Shamrao Vitthal Co-operative Bank",
  "South Indian Bank",
  "State Bank of Bikaner & Jaipur",
  "State Bank of Hyderabad",
  "State Bank of India",
  "State Bank of Mysore",
  "State Bank of Patiala",
  "State Bank of Travancore",
  "Syndicate Bank",
  "Tamilnad Mercantile Bank Ltd.",
  "UCO Bank",
  "Union Bank of India",
  "United Bank of India",
  "Vijaya Bank",
  "Yes Bank Ltd",
];
