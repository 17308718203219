import React, { createContext, useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { getstaffById } from "./components/staff/staffservice";
import io from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import { resetUser, setUser } from "./reducers/userreducer";
const baseURL = process.env.REACT_APP_SERVER_URL;
const AuthContext = createContext();
const socket = io(baseURL);
export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userId, setUserId] = useState("");

  const userDetail = useSelector((state) => state.user);
  const login = (id = "") => {
    setIsAuthenticated(true);
    setUserId(id);
  };

  const logout = (id = "") => {
    dispatch(resetUser());
    setIsAuthenticated(false);
    setUserId("");
    sessionStorage.removeItem("authToken");
    localStorage.removeItem("authToken");
    navigate("/login");
  };

  const fetchUserById = async () => {
    if (userId) {
      const response = await getstaffById(userId);

      if (response) {
        dispatch(setUser(response));
        // setUserDetail(response);
        setUserId(response?._id);
      }
    }
  };
  useEffect(() => {
    const token = sessionStorage.getItem("authToken");
    const id = sessionStorage.getItem("userId");
    if (token || localStorage.getItem("authToken")) {
      setIsAuthenticated(true);
      // setUserId(id ? id : "");
      // fetchUserById(id);
    } else {
      setIsAuthenticated(false);
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    fetchUserById();
  }, [userId]);

  useEffect(() => {
    socket.on("connection", (item) => {
      console.log(item);
    });

    return () => {
      socket.off("item created", (item) => {
        console.log(item);
      });
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, login, logout, userId, userDetail, socket }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
