import React, { useState } from "react";
import { resetpassword } from "../staffservice";
import { showToast } from "../../../utils/notification";
import { useAuth } from "../../../authprovider";
import { useNavigate } from "react-router-dom";

export default function Resetpassword() {
  const [password, setPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [confurmpassword, setConfurmPassword] = useState("");
  const { logout } = useAuth();
  const navigator = useNavigate();
  const [loding, setLoding] = useState(false);

  const resetPassword = async () => {
    if (newpassword != confurmpassword) {
      showToast("please confirm again your password", "warning");
      setNewPassword("");
      setConfurmPassword("");
      return;
    }
    setLoding(true);
    const response = await resetpassword({
      password: password,
      newpassword: newpassword,
    });
    setLoding(false);
    if (response && response.status == 200) {
      showToast("your password update successfully", "success");
      sessionStorage.removeItem("authToken");
      localStorage.removeItem("authToken");
      logout();
      navigator("/login");
    } else {
      showToast("Worng current password", "error");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    resetPassword();
  };
  return (
    <>
      <div className="content-wrapper">
        <div className="d-flex mb-4 align-content-center">
          <div className="page-header">
            <h1 className="page-title">Change Password</h1>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 grid-margin stretch-card">
            <div>
              <form onSubmit={handleSubmit} className="forms-sample mb-4">
                <div className="form-group row mb-4">
                  <div className="col-md-12  mb-4">
                    <label>Current Password</label>

                    <input
                      required={true}
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      type="password"
                      className="form-control"
                      placeholder="Enter"
                    />
                  </div>

                  <div className="col-md-12  mb-4">
                    <label>New Password</label>
                    <input
                      required={true}
                      value={newpassword}
                      onChange={(e) => {
                        setNewPassword(e.target.value);
                      }}
                      type="password"
                      className="form-control"
                      placeholder="Enter"
                    />
                  </div>

                  <div className="col-md-12  mb-4">
                    <label>Confirm Password</label>

                    <input
                      required={true}
                      value={confurmpassword}
                      onChange={(e) => {
                        setConfurmPassword(e.target.value);
                      }}
                      type="password"
                      className="form-control"
                      placeholder="Enter"
                    />
                  </div>
                </div>

                <button
                  disabled={loding}
                  type="submit"
                  // onClick={() => {
                  //   resetPassword();
                  // }}
                  className="btn btn-primary"
                >
                  Update
                </button>
                <button
                  disabled={loding}
                  onClick={() => {
                    navigator("/");
                  }}
                  className="btn btn-light ms-2"
                >
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
