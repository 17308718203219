import React, { useState } from "react";
import Caseform from "../caseform";
import { createNewCase } from "../caseservice";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../../utils/notification";
export default function Addcase() {
  const [loder, setLoder] = useState(false);

  const navigate = useNavigate();
  const postCase = async (value) => {
    let payload = {};
    if (value) {
      const createpayload = {
        clientId: value.clientId,
        productType: value.productType,
        productName: value.productName,
        vendorId: value.vendorId,
        accountNumber: value.accountNumber,
        requirement: value.requirement,

        source: value.source,
        assignTo: value.assignTo,
        remark: value.remark,
        status: value.status,
      };
      payload = createpayload;
    }
    setLoder(true);
    const response = await createNewCase(payload);
    setLoder(false);
    if (response && response.status == 200) {
      showToast("Case Added Successfully", "success");
      navigate("/all-cases");
    } else if (response && response.status == 400) {
      showToast("Account Number Already Exist", "warning");
    }
  };

  return (
    <>
      <Caseform
        submitvalue={(val) => {
          postCase(val);
        }}
        loder={loder}
      />
    </>
  );
}
