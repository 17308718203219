import { getRequest, postRequest, putRequest } from "../../utils/axiosrequests";

export const getAllVendors = (pageno, pagesize) => {
  return postRequest("/vendor", {
    pageno: pageno,
    pagesize: pagesize,
  })
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const getVendorById = (id) => {
  return getRequest(`/vendor/${id}`)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const updateVendorDetails = (caseId, data) => {
  return putRequest(`/vendor/${caseId}`, data)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const createNewVendor = (data) => {
  return postRequest(`/vendor/create`, data)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const getAllActiveVendors = (status = true) => {
  return postRequest("/vendor/data/getvendorbystatus", {
    status: status,
  })
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};
// /data/bulkstatusupdate

export const updateVendorBulkStatus = (data) => {
  return putRequest(`/vendor/data/bulkstatusupdate`, data)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const getAllVendorfreesearch = (data) => {
  return postRequest("/vendor/search", data)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};
