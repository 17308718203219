import { postRequest } from "../utils/axiosrequests";

export const userLogin = (data) => {
  return postRequest("/users/login", data)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};
