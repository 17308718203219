import React, { useEffect, useState } from "react";
import { getCasestatussummery } from "../../dashboard/dashboardservice";
import { useAuth } from "../../../authprovider";

export default function Myperformance() {
  const { userDetail, socket } = useAuth();
  const [statussummery, setStatusSummery] = useState(null);

  const fetchcasestatus = async () => {
    let data = { userId: userDetail._id };

    const response = await getCasestatussummery(data);

    if (response) {
      setStatusSummery(response);
    }
  };

  useEffect(() => {
    fetchcasestatus();
  }, [userDetail]);

  return (
    <>
      <div className="content-wrapper">
        <div className="d-flex mb-4 align-content-center">
          <div className="page-header">
            <h1 className="page-title">My Performance</h1>
          </div>
        </div>

        <div className="form-group d-flex mb-4">
          <div className="flex-fill ms-3">
            <label>New</label>
            <p className="cnew_color h5 font-weight-bold">
              {" "}
              {statussummery?.New ? statussummery.New?.count : 0}
            </p>
          </div>

          <div className="flex-fill ms-3">
            <label>In Process</label>
            <p className="text-warning h5 font-weight-bold">
              {" "}
              {statussummery?.In_Process ? statussummery.In_Process?.count : 0}
            </p>
          </div>

          <div className="flex-fill ms-3">
            <label>Closed</label>
            <p className="cclosed_color h5 font-weight-bold">
              {" "}
              {statussummery?.Closed ? statussummery.Closed?.count : 0}
            </p>
          </div>

          <div className="flex-fill ms-3">
            <label> CNV </label>
            <p className="ccnv_color h5 font-weight-bold">
              {" "}
              {statussummery?.CNV ? statussummery.CNV?.count : 0}
            </p>
          </div>

          <div className="flex-fill ms-3">
            <label> Error </label>
            <p className="cerror_color h5 font-weight-bold">
              {" "}
              {statussummery?.Error ? statussummery.Error?.count : 0}
            </p>
          </div>

          <div className="flex-fill ms-3">
            <label> Invalid </label>
            <p className="cinvalid_color h5 font-weight-bold">
              {statussummery?.Invalid ? statussummery.Invalid?.count : 0}
            </p>
          </div>

          <div className="flex-fill ms-3">
            <label> Total </label>
            <p className="text-primary h5 font-weight-bold">
              {statussummery?.totalCount ? statussummery.totalCount : 0}
            </p>
          </div>
        </div>

        <div className="performance_img">
          <img src="assets/images/performance_img.jpg" />
        </div>
      </div>
    </>
  );
}
